/*------------------------------------*\
    $MEDIA
\*------------------------------------*/

/**
 * Place any image- and text-like content side-by-side
 */

.media {
    display: flex;
    margin-bottom: $base-spacing-unit;
}

    .media-figure {
        margin-right: $base-spacing-unit;
        display: block;
    }

    .media-body {
        flex: 1 1 0%;

        &,
        > :last-child {
            margin-bottom: 0;
        }
    }


/**
 * Breakpoint modifiers
 */


@include media-query(desk) {
    .media-desk {
        display: flex;
        margin-bottom: $base-spacing-unit;
    }

        .media-desk-figure {
            margin-right: $base-spacing-unit;
            display: block;
        }

        .media-desk-body {
            flex: 1 1 0%;

            &,
            > :last-child {
                margin-bottom: 0;
            }
        }

    .media-desk-center {
        align-items: center;
    }
}
