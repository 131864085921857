.case-studies {
    &__nav {
        margin-right: $base-spacing-unit;

        .case-studies__buttons {
            margin-bottom: $base-spacing-unit * 3;
        }

        .btn {
            span {
                @include media-query("1600") {
                    margin-right: 0;
                }
            }

            svg.case-studies-btn-icon{
                @include media-query("1600"){
                    display: none;
                }
            }
        }
    }

    &__study {
        margin: 0 $base-spacing-unit;

        display: inline-block;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        position: relative;

        .case-image {
            height: 240px;
        }

        div {
            width: 100%;
            img {
                width: 100%;
                object-fit: cover;
            }

            .featured {
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(10px, 10px);
            }
        }

        // &::before {
        //     content: "";
        //     display: inline-block;
        //     vertical-align: middle;
        //     height: 100%;
        // }

        > * {
            // display: flex;
            align-items: center;
        }

        .title {
            width: 57%;
            // font-weight: 600;
            color: #fff;
            margin-top: $base-spacing-unit;
        }

        @include media-query(narrow) {
            &:first-child {
                margin-left: 0 !important;
            }
        }

        @include media-query(portable) {
            &:first-child {
                margin-left: 0 !important;
            }
        }
    }

    &__button {
        border-radius: 50%;
        border: 2px solid $colour-primary;
        $size: 60px;
        width: $size;
        height: $size;
        position: relative;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &.right {
        }

        &.left {
            transform: rotate(180deg);
            margin-right: $base-spacing-unit;
        }
    }

    &__read-article {
        border-bottom: 2px solid $colour-primary;
        padding-bottom: $base-spacing-unit;
        width: 100%;
        display: block;
    }

    .splide__list {
        @include media-query(portable) {
            margin-top: $base-spacing-unit !important;
        }
    }

    .splide__track {
        @include media-query(wide) {
            // margin-top: $base-spacing-unit !important;
        }
    }
}

