/*------------------------------------*\
    $LINK
\*------------------------------------*/

.link {
    padding-left: 20px;
    text-indent: -20px;

    &::before {
        content: "";
        margin-right: halve($base-spacing-unit);
        display: inline-block;
        width: 8px;
        height: 8px;
        border: 2px solid $colour-primary;
        border-radius: 100px;
    }
}

.link-text {
    color: $colour-secondary;
}
