/*------------------------------------*\
    $LINK-BARE
\*------------------------------------*/

// Define reusable mixin
@mixin link-bare {
    color: currentColor;
    text-decoration: none;

    &:focus,
    &:hover,
    &:active {
        color: $colour-secondary;
    }
}


/**
 * Unstyle links
 */

.link-bare {
    @include link-bare;
}
