/*------------------------------------*\
    $LOCK
\*------------------------------------*/

.is-locked {
    &,
    body {
        overflow: hidden;
    }

    &.touchevents {
        body {
            position: fixed;
            width: 100%;
        }
    }
}
