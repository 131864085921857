/*------------------------------------*\
    $HEADINGS
\*------------------------------------*/

// Predefine heading mixins which can be reused in trumps layer later
@mixin heading {
    font-weight: 500;
    color: $colour-ui-dark;
}

@mixin heading-1 {
    @include font-size(24px, 1.3);
}

@mixin heading-2 {
    @include font-size(24px, (29px / 24px));

    @include media-query(desk) {
        @include font-size(32px, 38px / 29px)
    }
}

@mixin heading-3 {
    @include font-size(16px, (21px / 16px));

    @include media-query(desk) {
        @include font-size(24px, (29px / 24px));
    }
}

@mixin heading-4 {
    @include font-size(14px);

    @include media-query(desk) {
        @include font-size(18px, (26px / 18px));
    }
}

@mixin heading-5 {
    @include font-size(14px);
}

@mixin heading-6 {
    @include font-size(14px);
}


/**
 * Declare base heading sizes and styles
 */

h1, h2, h3, h4, h5, h6 {
    @include heading;
}

h1 {
    @include heading-1;
}

h2 {
    @include heading-2;
}

h3 {
    @include heading-3;
}

h4 {
    @include heading-4;
}

h5 {
    @include heading-5;
}

h6 {
    @include heading-6;
}
