/*------------------------------------*\
    $ALIGN
\*------------------------------------*/

/**
 * Alignment utility classes
 */

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

/**
 * Alignment based on a breakpoint
 */

@include media-query(lap-and-up) {
    .align-center-lap-and-up {
        text-align: center;
    }
}

@include media-query(desk) {
    .align-center-desk {
        text-align: center;
    }

    .align-right-desk {
        text-align: right;
    }
}
