/*------------------------------------*\
    $LIST-INLINE
\*------------------------------------*/

/**
 * Throw lists into inline mode
 */

.list-inline {
    @include list-bare;

    > li {
        display: inline-block;
    }
}
