/*------------------------------------*\
    $TABLE
\*------------------------------------*/

.table-rwd {
    overflow: scroll;
}

.table {
    width: 100%;

    .accordion-panel & {
        margin: 0 (0 - $base-spacing-unit);
        width: calc(100% + #{double($base-spacing-unit)});
        table-layout: fixed;
        border-top: 1px solid $colour-ui;
    }

    td, th {
        padding: halve($base-spacing-unit) $base-spacing-unit;
        border-bottom: 1px solid $colour-ui-light;
        vertical-align: top;

        @include media-query(desk) {
            padding: $base-spacing-unit;
        }
    }

    tr:last-child {
        td, th {
            border-bottom: 0;
        }
    }


    td {
        background-color: #fef7f2;

        &:nth-child(3) {
            background-color: #fdefe4;
        }

        &:nth-child(4) {
            background-color: #fce8d8;
        }

        &:nth-child(5) {
            background-color: #fbe0cb;
        }

        &:nth-child(6) {
            background-color: #ebd5c1;
        }

        &:nth-child(7) {
            background-color: #decab6;
        }
    }

    th {
        font-weight: 500;
        text-align: left;
    }

    thead {
        th:not(:empty) {
            background-color: $colour-primary;
            color: $colour-ui-light;

            &:nth-child(3) {
                background-color: #e87a24;
            }

            &:nth-child(4) {
                background-color: #e17723;
            }

            &:nth-child(5) {
                background-color: #d97322;
            }

            &:nth-child(6) {
                background-color: #c56f1e;
            }

            &:nth-child(7) {
                background-color: #bb681c;
            }
        }
    }

    tbody {
        th {
            background-color: $colour-ui;
            color: $colour-ui-dark;
        }
    }
}
