/*------------------------------------*\
    $LINKS
\*------------------------------------*/

a {
    color: $colour-primary;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        color: darken($colour-primary, 10%);
    }
}
