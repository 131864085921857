/*------------------------------------*\
    $NAV
\*------------------------------------*/

.nav {
    @include media-query(portable) {
        position: fixed;
        background-color: rgba($colour-secondary, 0.95);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: scroll;
        visibility: hidden;
        transition: opacity $base-anim-speed linear,
            visibility 0s $base-anim-speed;
        opacity: 0;
        z-index: 3;

        &.is-active {
            visibility: visible;
            opacity: 1;
            transition-delay: 0s;
        }
    }

    @include media-query(desk) {
        position: relative;
        z-index: 5;
    }
}

.nav-close {
    @include btn-bare;
    display: block;
    border-radius: 100px;
    width: double($base-spacing-unit);
    height: double($base-spacing-unit);
    background-color: $colour-primary;
    color: $colour-ui-light;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @include media-query(desk) {
        display: none;
    }
}

.nav-list {
    margin-bottom: 0;

    @include media-query(desk) {
        display: flex;
        border-top: 1px solid $colour-ui;

        .is-sticky & {
            box-shadow: 0 1px 0 $colour-ui;
        }
    }
}

.nav-item {
    position: relative;

    @include media-query(portable) {
        border-bottom: 1px solid rgba($colour-ui-light, 0.06);
    }

    @include media-query(desk) {
        flex: 1 1 0%;

        border-right: 1px solid $colour-ui-grey;
        border-top: 1px solid $colour-ui-grey;

        &:nth-child(2){
            border-left: 1px solid $colour-ui-grey;
        }
    }
}

.nav-link {
    display: block;
    color: $colour-ui-light;
    padding: 17px $base-spacing-unit;
    transition: color $base-anim-speed linear;

    &.is-active {
        color: $colour-primary;
    }

    @include media-query(portable) {
        font-weight: 500;
        @include font-size(18px);
    }

    @include media-query(desk) {
        color: $colour-secondary;
        padding: halve($base-spacing-unit);
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover,
        &:focus,
        &:active {
            color: $colour-primary;
        }
    }
}

