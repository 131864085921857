/*------------------------------------*\
    $CALC
\*------------------------------------*/

/**
 * Complex component consisting of completely different mobile and
 * desktop styles, hence split out into massive individual MQs
 */

.calc-title {
    text-transform: uppercase;
    font-weight: bold;
    
    span {
        color: $colour-primary;
    }
    
    @include media-query(desk) {
        @include font-size(45px, false);
    }
}

@include media-query(portable) {
    .calc {
        background-color: $colour-ui;
    }

    .calc-label {
        font-weight: 500;
        color: $colour-ui-dark;
        margin-bottom: halve($base-spacing-unit);
        text-align: center;
    }

    .calc-list {
        border-radius: 6px;
        border-top: 2px solid $colour-ui-light;
        border-left: 2px solid $colour-ui-light;
    }

    .calc-list-item {
        &:first-child .calc-btn {
            border-radius: 6px 6px 0 0;
        }

        &:last-child .calc-btn {
            border-radius: 0 0 6px 6px;
        }
    }

    .calc-list-columns {
        display: flex;
        flex-wrap: wrap;

        .calc-list-item {
            width: 50%;

            &:nth-of-type(odd) .calc-btn {
                flex-direction: row;

                .calc-btn-check,
                .calc-btn-figure {
                    margin-right: halve($base-spacing-unit);
                    margin-left: 0;
                }
            }

            .calc-btn {
                border-radius: 0;
            }

            &:first-child .calc-btn {
                border-top-left-radius: 6px;
            }

            &:nth-child(2) .calc-btn {
                border-top-right-radius: 6px;
            }

            &:nth-last-child(2) .calc-btn {
                border-bottom-left-radius: 6px;
            }

            &:last-child .calc-btn {
                border-bottom-right-radius: 6px;
            }
        }
    }

    .calc-list-item,
    .calc-check {
        display: flex;
        flex-direction: column;
    }

    .calc-check {
        position: relative;
        flex: 1;
    }

    .calc-check-input {
        position: absolute;
        opacity: 0;

        &:checked ~ .calc-btn {
            color: $colour-primary;
            background-image: none;

            .calc-btn-check {
                &::before {
                    display: block;
                }
            }
        }
    }

    .calc-btn {
        flex: 1;
        background-image: linear-gradient(to bottom, $colour-ui-light 0%, #f0f0f0 100%);
        background-color: #f0f0f0;
        display: block;
        color: rgba($colour-secondary, .64);
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        cursor: pointer;
        border-right: 2px solid $colour-ui-light;
        border-bottom: 2px solid $colour-ui-light;
        padding: 18px;
    }

    .calc-btn-body {
        flex: 1;
        text-align: left;
    }

    .calc-btn-figure,
    .calc-btn-check {
        margin-left: halve($base-spacing-unit);
    }

    .calc-btn-check {
        width: $base-spacing-unit;
        height: $base-spacing-unit;
        border: 2px solid $colour-ui-light;
        border-radius: 100px;
        background-image: linear-gradient(to bottom, #ededed 0%, #f4f4f4 100%);
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $colour-primary;
            width: 8px;
            height: 8px;
            border-radius: 100px;
            display: none;
        }
    }

    .calc-value {
        display: none;
    }
}

@include media-query(palm) {
    .calc-summary {
        background-color: #d5d7d8;
        padding: $base-spacing-unit $base-spacing-unit 1px;
        margin-left: -$base-spacing-unit;
        margin-right: -$base-spacing-unit;
        margin-bottom: -1px;
    }
}


/**
 * Style results boxes
 */

.calc-result {
    border: 2px solid $colour-ui;
    margin-bottom: $base-spacing-unit;

    .band-ui & {
        border-color: $colour-ui-light;
    }

    @include media-query(desk) {
        display: flex;
    }
}

    .calc-result-title {
        @include font-size(12px);
        font-weight: bold;
        color: $colour-ui-dark;
        padding: 10px;
        text-transform: uppercase;

        @include media-query(desk) {
            @include font-size(16px);
        }
    }

    .calc-result-value {
        background-color: $colour-ui;
        color: $colour-primary;
        @include font-size(18px);
        font-weight: 500;
        padding: 15px 10px;

        .band-ui & {
            background-color: $colour-ui-light;
        }

        @include media-query(desk) {
            @include font-size(24px);
        }
    }

    .calc-result-title,
    .calc-result-value {
        @include media-query(desk) {
            flex: 1 1 0%;
            padding: 19px $base-spacing-unit;
        }
    }

.calc-footnote {
    @include font-size(12px, (20px / 12px));
    color: rgba($colour-secondary, .64);
}


/**
 * Desktop dial-like styles
 */

@include media-query(desk) {
    .calc-fields {
        border-top: 1px solid rgba($colour-secondary, .12);
        padding-top: $base-spacing-unit;
        padding-bottom: double($base-spacing-unit);
        border-bottom: 1px solid rgba($colour-secondary, .12);
        margin-bottom: 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

        .calc-fields-item {
            width: percentage(1/3);
            text-align: center;
            display: flex;
            flex-direction: column-reverse;
            margin-bottom: $base-spacing-unit;
        }

    .calc-label {
        color: $colour-secondary;
        margin-bottom: 0;
    }

    .calc-value {
        @include font-size(24px);
        color: $colour-primary;
        font-weight: 500;

        &[data-suffix]::after {
            content: attr(data-suffix);
        }
    }

    .calc-knob {
        cursor: pointer;
        position: relative;
        width: 108px;
        height: 108px;
        margin: $base-spacing-unit auto halve($base-spacing-unit);
        background-color: rgba(#000, .03);
        border-radius: 999px;
        box-shadow: 0 1px 3px rgba(#000, .12) inset;

        &::before {
            content: "";
            position: absolute;
            border-radius: 999px;
            background-color: $colour-ui-light;
            background-image: linear-gradient(to bottom, #fff 0%, #f0f0f0 100%);
            border: 2px solid $colour-ui-light;
            box-shadow: 0 1px 3px rgba(#000, .24);
            width: 96px;
            height: 96px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            border-left: 10px solid $colour-primary;
            width: calc(50% - 18px);
            height: 2px;
            left: 18px;
            transform-origin: right center;
            transition: transform $base-anim-speed ease-in-out;
        }
    }

    .calc-knob-2 {
        &::after {
            transform: rotate(45deg);
        }

        .calc-list-item:nth-child(1) .calc-check {
            right: calc(100% - 6px);
            bottom: calc(100% - 6px);
        }

        .calc-list-item:nth-child(2) .calc-check {
            left: calc(100% - 6px);
            bottom: calc(100% - 6px);
        }

        &.is-at-2::after {
            transform: rotate(135deg);
        }
    }

    .calc-knob-4 {
        .calc-list-item:nth-child(1) .calc-check {
            top: 50%;
            right: calc(100% + 16px);
            transform: translateY(-50%);
        }

        .calc-list-item:nth-child(2) .calc-check {
            right: calc(100% - 6px);
            bottom: calc(100% - 6px);
        }

        .calc-list-item:nth-child(3) .calc-check {
            left: calc(100% - 6px);
            bottom: calc(100% - 6px);
        }

        .calc-list-item:nth-child(4) .calc-check {
            top: 50%;
            left: calc(100% + 16px);
            transform: translateY(-50%);
        }

        &.is-at-2::after {
            transform: rotate(45deg);
        }

        &.is-at-3::after {
            transform: rotate(135deg);
        }

        &.is-at-4::after {
            transform: rotate(180deg);
        }
    }

    .calc-check {
        position: absolute;
        cursor: pointer;

        &:hover {
            z-index: 1;
        }
    }

        .calc-check-input {
            position: absolute;
            opacity: 0;
        }

    .calc-btn {
        color: rgba($colour-ui-dark, .32);
        display: block;

        .calc-check-input:checked ~ & {
            color: $colour-primary;
        }
    }

        .calc-btn-figure {
            width: $base-spacing-unit;
            height: $base-spacing-unit;
        }

        .calc-btn-body {
            position: absolute;
            left: 50%;
            bottom: 100%;
            transform: translateX(-50%);
            opacity: 0;
            visibility: hidden;
            transition: opacity $base-anim-speed linear, visibility 0s $base-anim-speed;
            background-color: rgba($colour-ui-dark, .88);
            color: $colour-ui-light;
            padding: 8px $base-spacing-unit;
            border-radius: 3px;
            margin-bottom: 10px;
            white-space: nowrap;
            backface-visibility: hidden;
            pointer-events: none;

            &::before {
                content: "";
                position: absolute;
                left: 50%;
                top: 100%;
                border: 6px solid transparent;
                border-top-color: rgba($colour-ui-dark, .88);
                transform: translateX(-50%);
            }

            .calc-check:hover & {
                opacity: 1;
                visibility: visible;
                transition-delay: 0s;
            }
        }

    .calc-dial,
    .calc-dial-track {
        position: relative;
        width: 144px;
        height: 72px;
        cursor: pointer;
    }

    .calc-dial {
        margin: $base-spacing-unit auto 18px;

        .calc-list-item:nth-child(1) .calc-check {
            bottom: 0;
            right: calc(100% + #{halve($base-spacing-unit)});
        }

        .calc-list-item:nth-child(2) .calc-check {
            bottom: calc(100% - 16px);
            right: calc(100% - #{halve($base-spacing-unit)});
        }

        .calc-list-item:nth-child(3) .calc-check {
            left: 50%;
            bottom: calc(100% + #{halve($base-spacing-unit)});
            transform: translateX(-50%);
        }

        .calc-list-item:nth-child(4) .calc-check {
            bottom: calc(100% - 16px);
            left: calc(100% - #{halve($base-spacing-unit)});
        }

        .calc-list-item:nth-child(5) .calc-check {
            bottom: 0;
            left: calc(100% + #{halve($base-spacing-unit)});
        }

        .calc-btn {
            &::before {
                content: attr(data-label);
                white-space: nowrap;
            }
        }

        &::after {
            content: "";
            position: absolute;
            background-image: url(../img/dial.svg);
            width: 48px;
            height: 48px;
            left: 50%;
            bottom: 0px;
            transform: translate(-50%, 50%) rotate(10deg);
            transition: transform $base-anim-speed ease-in-out;
        }

        &.is-at-2::after {
            transform: translate(-50%, 50%) rotate(45deg);
        }

        &.is-at-3::after {
            transform: translate(-50%, 50%) rotate(90deg);
        }

        &.is-at-4::after {
            transform: translate(-50%, 50%) rotate(135deg);
        }

        &.is-at-5::after {
            transform: translate(-50%, 50%) rotate(180deg);
        }
    }

        .calc-dial-track {
            background-color: rgba(#000, .03);
            box-shadow: 0 1px 3px rgba(#000, .12) inset;
            overflow: hidden;
            backface-visibility: hidden;

            &,
            &::after {
                border-radius: 999px 999px 0 0;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 112px;
                height:  56px;
                transform: translateX(-50%);
                box-shadow: 0 1px 3px rgba(#000, .12);
                background-color: #fff;

                .band-ui & {
                    background-color: $colour-ui;
                }
            }
        }

        .calc-dial-value {
            position: absolute;
            border-radius: 0 0 999px 999px;
            border: 10px solid $colour-primary;
            border-top: none;
            width: calc(100% - 6px);
            height: 69px;
            top: 100%;
            left: 3px;
            transition: transform $base-anim-speed ease-in-out;
            transform-origin: top center;
            backface-visibility: hidden;
            transform: rotate(10deg);

            .is-at-2 & {
                transform: rotate(45deg);
            }

            .is-at-3 & {
                transform: rotate(90deg);
            }

            .is-at-4 & {
                transform: rotate(135deg);
            }

            .is-at-5 & {
                transform: rotate(180deg);
            }
        }
}
