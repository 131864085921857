/*------------------------------------*\
    $SHARED
\*------------------------------------*/

/**
 * Setup bottom margins for vertical rhythm
 */

h1, h2, h3, h4, h5, h6,
ol, ul, dl,
blockquote, p, address,
hr,
table,
fieldset, figure,
pre {
    margin-bottom: $base-spacing-unit;
    margin-bottom: ($base-spacing-unit / $base-font-size) * 1rem;
}


/**
 * Setup consistent left margins for lists
 */

// ol, ul, dd {
//     margin-left: double($base-spacing-unit);
//     margin-left: (double($base-spacing-unit) / $base-font-size) * 1rem;
// }
