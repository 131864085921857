/*------------------------------------*\
    $PAGE
\*------------------------------------*/

.page {
    overflow-x: hidden;
}

    .page-content {
        @include media-query(desk) {
            .band-ui:last-child {
                margin-bottom: -6px;
            }
        }
    }
