/*------------------------------------*\
    $TYPOGRAPHY
\*------------------------------------*/

.typography {
    img {
        display: block;
        margin: 0 auto $base-spacing-unit;
    }

    ul, ol {
        margin-left: 32px;
        overflow: hidden;

        li {
            padding-left: 20px;
            text-indent: -20px;

            &::before {
                content: "";
                display: inline-block;
                vertical-align: middle;
                width: 8px;
                height: 4px;
                background-color: $colour-primary;
                margin-right: 12px;
            }
        }
    }

    ol {
        counter-reset: numbers;

        li {
            counter-increment: numbers;

            &::before {
                content: counter(numbers);
                color: $colour-primary;
                background-color: transparent;
                width: auto;
                height: auto;
                text-indent: 0;
                font-weight: bold;
            }
        }
    }

    blockquote {
        border-left: 6px solid $colour-primary;
        padding-left: 22px;
        color: $colour-ui-dark;
        font-style: italic;
        font-family: $font-quote;
        @include font-size(18px, (32px / 18px));
    }

    a {
        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }

    a[href*="twitter.com/intent/tweet"] {
        color: #55acee;

        &:hover,
        &:focus,
        &:active {
            color: #2395ED;
        }

        span {
            position: relative;
            background-color: currentColor;
            display: inline-block;
            vertical-align: middle;
            width: $base-spacing-unit;
            height: $base-spacing-unit;
            margin-left: 11px;
            margin-right: 6px;
            border-radius: 2px;

            .icon {
                color: #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 12px;
                height: 12px;
            }

            &::before {
                content: "";
                border: 3px solid transparent;
                border-right-color: currentColor;
                position: absolute;
                top: 50%;
                right: 100%;
                transform: translateY(-50%);
            }
        }
    }

    @include media-query(lap-and-up) {
        .alignleft {
            margin-right: $base-spacing-unit;
            float: left;
            max-width: 50%;
        }

        .alignright {
            margin-left: $base-spacing-unit;
            float: right;
            max-width: 50%;
        }
    }
}


.list-stylised {
    display: flex;
    flex-wrap: wrap;
    counter-reset: numbers;
    margin-bottom: halve($base-spacing-unit);
    margin-left: -$base-spacing-unit;

    @include media-query(desk) {
        margin-bottom: 0;
    }

    li {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        counter-increment: numbers;
        color: $colour-primary;
        margin-bottom: halve($base-spacing-unit);
        margin-left: $base-spacing-unit;

        @include media-query(desk) {
            margin-bottom: $base-spacing-unit;
        }

        span {
            flex: 1 1 0%;
        }

        &::before {
            content: counter(numbers);
            border-radius: 99px;
            color: $colour-secondary;
            width: 48px;
            line-height: 44px;
            border: 2px solid $colour-ui;
            text-align: center;
            margin-right: 12px;

            @include media-query(desk) {
                width: 64px;
                line-height: 62px;
            }
        }
    }
}
