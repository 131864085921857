.testimonials {
    .splide__track {
        z-index: 999;
    }

    .splide__pagination__page {
        background-color: $colour-primary;
        border: 1px solid $colour-primary;

        &.is-active {
            border: 1px solid $colour-primary;
            box-sizing: border-box;
            height: 6px;
            width: 6px;
        }
    }

    .splide__pagination {
        bottom: 2.5em;
    }

    &__content {
        font-weight: bold;
        z-index: 20;
    }

    &__author {
        font-weight: light !important;
        padding-top: $base-spacing-unit;
    }

    &__box {
        position: relative;

        .testimonials__box__arrow {
            position: absolute;
            border-radius: 50%;
            background-color: $colour-primary !important;
            $size: 48px;
            width: $size;
            height: $size;
            border: none;

            &:hover {
                cursor: pointer;
            }

            &.left {
                left: 0;
                transform: translateX(-50%);
            }

            &.right {
                right: 0;
                transform: translateX(50%);
            }

            .icon {
                fill: white;

                &.left {
                    transform: rotate(180deg);
                }
            }
        }

        .quotes-icon {
            svg {
                $size: 160px;
                width: $size;
                height: $size;
                position: absolute;
                fill: $colour-ui;
                z-index: 1;

                &.top-left {
                    top: 5%;
                    left: 5%;
                }

                &.bottom-right {
                    bottom: 20%;
                    right: 5%;
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__bg {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

