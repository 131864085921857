/*------------------------------------*\
    $PAGE
\*------------------------------------*/

/**
 * Set font defaults, base colours and high-level page stylings
 */

html {
    font: #{($base-font-size/16px)*1em}/#{$base-line-height/$base-font-size} $base-font;
    color: $base-text-colour;
    background-color: $base-background-colour;
    overflow-y: scroll;
    min-height: 100%;
}


/**
 * Set base branded user interactions
 */

::-moz-selection {
    background-color: $colour-primary;
    color: #fff;
    text-shadow: none;
}

::selection {
    background-color: $colour-primary;
    color: #fff;
    text-shadow: none;
}

// :focus {
//     outline: $colour-primary auto 5px;
// }
