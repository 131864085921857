/*------------------------------------*\
    $SECTOR
\*------------------------------------*/

.sector-list {
    :nth-child(1) .sector {
        background-color: $colour-secondary-2;
    }

    :nth-child(2) .sector {
        background-color: $colour-secondary-3;
    }

    :nth-child(3) .sector {
        background-color: $colour-secondary-4;
    }

    :nth-child(4) .sector {
        background-color: $colour-secondary-5;
    }

    :nth-child(5) .sector {
        background-color: $colour-secondary-6;
    }
}

.sector,
.sector-title {
    color: $colour-ui-light;
}

.sector {
    display: block;
    padding: 20px;

    @include media-query(portable) {
        background-image: none !important;
    }

    @include media-query(desk) {
        padding: 32px 0 0;
        min-height: 520px;
        display: block;
        background-size: cover;
        background-position: center center;
        position: relative
    }

    .btn {
        color: $colour-ui-light;
        border-color: $colour-primary;
    }

    &:hover,
    &:focus,
    &:active {
        .btn {
            color: #fff;
            background-color: $colour-primary;
        }
    }
}

@include media-query(desk) {
    .sector-wrap {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.sector-heading {
    @include media-query(portable) {
        display: flex;
        align-items: center;
    }

    @include media-query(desk) {
        .icon {
            display: none;
        }
    }
}

.sector-title {
    @include media-query(portable) {
        margin-bottom: 0;
        flex: 1 1 0%;
        margin-left: 16px;
        margin-right: 16px;
    }

    @include media-query(desk) {
        position: relative;
        z-index: 1;
        margin-left: 32px;
        margin-right: 32px;
    }
}

.sector-body {
    @include media-query(portable) {
        display: none;
    }

    @include media-query(desk) {
        background-color: rgba($colour-ui-dark, .64);
        position: relative;
        color: rgba($colour-ui-light, .64);
        padding: 0 32px (32px - $base-spacing-unit);
        // margin-left: -32px;
        // margin-right: -32px;
        max-width: 100%;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 100%;
            width: 100%;
            height: 180px;
            background-image: linear-gradient(to top, rgba($colour-ui-dark, .64) 0%, transparent 100%);
        }
    }
}
