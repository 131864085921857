//------------------------------------*\
//  $SETTINGS-BASE
//------------------------------------*/

// High level base settings
$base-font-size:            14px;
$base-line-height:          24px;
$base-spacing-unit:         $base-line-height;
$base-text-colour:          #485156;
$base-background-colour:    #fff;
$base-font:                 'Encode Sans';
$base-anim-speed:           300ms;


// Colour Palette
$colour-primary:            #ef7e25;
$colour-primary-hover:      #e86400;
$colour-secondary:          $base-text-colour;
$colour-secondary-2:        #535b60;
$colour-secondary-3:        #596165;
$colour-secondary-4:        #5e666b;
$colour-secondary-5:        #636b6f;
$colour-secondary-6:        #70777b;
$colour-tertiary:           #60b0d3;
$colour-tertiary-hover:     #1b9cd3;
$colour-quaternary:         #94c13d;
$colour-quaternary-hover:   #7cba00;

$colour-ui-dark:            #1f1a16;
$colour-ui:                 #e9eaea;
$colour-ui-grey:            #4e5860; 
$colour-ui-light:           #fff;

$colour-valid:              $colour-quaternary;
$colour-invalid:            $colour-primary;

// Typefaces
$font-primary:              $base-font;
$font-quote:                'Libre Baskerville'
