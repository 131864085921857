.flex {
    display: flex;
}

.reverse {
    flex-direction: row-reverse;
}

.row {
    flex-direction: row;
}

.column {
    flex-direction: column;
}

.wrap {
    flex-wrap: wrap;
}

.jc-start {
    justify-content: flex-start;
}

.jc-end {
    justify-content: flex-end;
}

.jc-center {
    justify-content: center;
}

.jc-between {
    justify-content: space-between;
}

.jc-around {
    justify-content: space-around;
}

.jc-even {
    justify-content: space-evenly;
}

.ai-start {
    align-items: flex-start;
}

.ai-end {
    align-items: flex-end;
}

.ai-center {
    align-items: center;
}

.ai-stretch {
    align-items: stretch;
}

// Flex Item Class with Flex Basis Widths
.flex-item {
    flex-grow: 0;
    flex-shrink: 0;
}

.full {
    flex-basis: 100%;
}

.half {
    flex-basis: 50%;
}

.forty {
    flex-basis: 40%;
}

.third {
    flex-basis: 33.33%;
}

.two-thirds {
    flex-basis: 66.66%;
}

.basis-62 {
    flex-basis: 62.66%;
}

.quarter {
    flex-basis: 25%;
}

.three-quarters {
    flex-basis: 75%;
}

.ten {
    flex-basis: 10%;
}

.fifteen {
    flex-basis: 15%;
}

.twenty {
    flex-basis: 20%;
}

/**
    RESPONSIVE
*/

@include media-query("1500"){
    .column-xlg{
        flex-direction: column;
    }

    .half-xlg{
        flex-basis: 50%;
    }
}

@include media-query("1400"){
    .column-lg{
        flex-direction: column;
    }

    .half-lg{
        flex-basis: 50%;
    }

    .column-lg{
        flex-direction: column;
    }
}

@include media-query(narrow) {
    .column-reverse-md {
        flex-direction: column-reverse;
    }

    .row-md {
        flex-direction: row;
    }

    .column-md {
        flex-direction: column;
    }

    .ai-center-md {
        align-items: center;
    }

    .half-md{
        flex-basis: 50%;
    }
}

@include media-query(portable) {
    .column-sm {
        flex-direction: column;
    }

    .column-reverse-sm {
        flex-direction: column-reverse;
    }

    .ai-center-sm {
        align-items: center;
    }

    .ai-start-sm {
        align-items: flex-start;
    }

    .jc-center-sm {
        justify-content: center;
    }

    .full-sm {
        flex-basis: 100%;
    }

    .half-sm {
        flex-basis: 50%;
    }

    .third-sm {
        flex-basis: 33.33%;
    }

    .two-thirds-sm {
        flex-basis: 66.66%;
    }

    .quarter-sm {
        flex-basis: 25%;
    }

    .three-quarters-sm {
        flex-basis: 75%;
    }
}
