/*------------------------------------*\
    $BUTTONS
\*------------------------------------*/

.btn {
    display: inline-block;
    vertical-align: middle;
    font: inherit;
    text-align: center;
    cursor: pointer;
    border: 2px solid;
    margin: 0;
    padding: 10px 30px;
    border-radius: 100px;
    font-weight: 500;
    transition: color $base-anim-speed linear, background-color $base-anim-speed linear;

    &,
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }

    &.extra-padding{
        padding-left: 60px;
        padding-right: 60px;
    }
}


/**
 * Remove default styles from <input> or <button> elements
 */

@mixin btn-bare {
    font: inherit;
    border: none;
    margin: 0;
    padding: 0;
    background: none;
    outline: none;
}

.btn-bare {
    @include btn-bare;
}


/**
 * Size modifiers
 */

.btn-full {
    width: 100%;
}

.btn-med {
    @include font-size(18px);
    border-radius: 0;
    padding: $base-spacing-unit 32px;
    font-weight: 500;
}


/**
 * Complex media-like buttons
 */

.btn-media {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;

    .btn & {
        width: 100%;
    }
}

    .btn-icon {
        margin-right: $base-spacing-unit;
    }

    .btn-body {
        flex: 1 1 0;
        max-width: 100%;
        text-align: left;
        display: inline-block;

        /**
         * Edge hack
         */

        @supports (-ms-accelerator:true) {
            flex: 1 1 0%;
        }

        @supports (-ms-ime-align:auto) and (color:unset) {
            flex: 1;
        }

        /**
         * IE10 and 11
         */

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            margin-right: auto;
        }
    }

.btn-media-rev {
    flex-direction: row-reverse;
    // padding-right: 18px;

    .btn-icon {
        margin-left: $base-spacing-unit;
        margin-right: 0;
    }
}


/**
 * Colour heirarchy
 */

 .btn-transparent{
    border: 2px solid $colour-primary !important;
    background-color: transparent;
    color: $colour-primary;

 }

.btn-primary {
    background-color: $colour-primary;
    color: $colour-ui-light;
    border-color: transparent;

    &:hover,
    &:focus,
    &:active {
        background-color: #e86400;
        color: $colour-ui-light;
    }
}

.btn-tertiary {
    background-color: $colour-tertiary;
    color: $colour-ui-light;

    &:hover,
    &:focus,
    &:active {
        background-color: #1b9cd3;
        color: $colour-ui-light;
    }
}

.btn-tertiary-ghost {
    color: $colour-tertiary;
    border-color: rgba($colour-tertiary, .24);

    &:hover,
    &:focus,
    &:active {
        background-color: #1b9cd3;
        color: $colour-ui-light;
    }
}

.btn-quaternary {
    background-color: $colour-quaternary;
    color: $colour-ui-light;
    border-color: transparent;

    &:hover,
    &:focus,
    &:active {
        background-color: #7cba00;
        color: $colour-ui-light;
    }
}


/**
 * Remove rounded corners
 */

.btn-hard {
    border-radius: 0;
}
