/*------------------------------------*\
    $ALERT
\*------------------------------------*/

.alert {
    padding: halve($base-spacing-unit);
    color: $colour-ui-light;
    display: none;
}

.alert-invalid {
    background-color: $colour-invalid;
}

.alert-valid {
    background-color: $colour-valid;
}
