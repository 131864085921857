/*------------------------------------*\
    $GRID
\*------------------------------------*/

// Define grid gutter sizes
$grid-gutter-width: $base-spacing-unit !default;

// Define mixins
@mixin grid($gutter-width: $grid-gutter-width) {
    list-style: none;
    margin: 0 0 0 (0 - $gutter-width);
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

@mixin grid-item($gutter-width: $grid-gutter-width) {
    margin-left: $gutter-width;

    @if ($gutter-width != 0px) {
        width: calc(100% - #{$gutter-width});
    } @else {
        width: 100%;
    }

    .no-flexbox & {
        display: inline-block;
        vertical-align: top;
    }
}

@mixin grid-width($fraction, $gutter-width: $grid-gutter-width) {
    @if ($fraction == auto) {
        flex: 0 1 auto;
        width: auto;
    } @else {
        @if ($gutter-width != 0px) {
            width: calc(#{percentage($fraction)} - #{$gutter-width});
        } @else {
            width: #{percentage($fraction)};
        }
    }
}

/**
 * Base grid object
 */

.grid {
    @include grid;
}

.grid-item {
    @include grid-item;
}

/**
 * Gutter modifiers
 */

.grid-header-icons {
    margin: 0 0 0 0 !important;

    @include media-query(desk) {
        margin: 0 0 0 24px !important;
    }
}

.grid-flush {
    &,
    > .grid-item {
        margin-left: 0;
    }

    > .grid-item {
        width: 100%;
    }
}

.grid-sml {
    margin-left: -6px;

    > .grid-item {
        margin-left: 6px;
    }
}

.grid-med {
    margin-left: -32px;

    > .grid-item {
        margin-left: 32px;
    }
}

@include media-query(wide) {
    .grid-lrg {
        margin-left: -64px;

        > .grid-item {
            margin-left: 64px;
        }
    }
}

/**
 * Horizontal alignment modifiers
 */

.grid-center {
    justify-content: center;
}

/**
 * Vertical alignment modifiers
 */

.grid-middle {
    align-items: center;
}
