/*------------------------------------*\
    $HEADINGS
\*------------------------------------*/

/**
 * Dual-stranded trump heading styles, copied from
 * `../base/_headings.scss`
 */

.alpha, .beta, .gamma, .delta, .epsilon, .zeta {
    @include heading;
}

.alpha {
    @include heading-1;
}

.beta {
    @include heading-2;
}

.gamma {
    @include heading-3;
}

.delta {
    @include heading-4;
}

.epsilon {
    @include heading-5;
}

.zeta {
    @include heading-6;
}
