/*------------------------------------*\
    $PAGINATION
\*------------------------------------*/

.pagination {
    display: flex;
    padding-top: 56px;
    padding-bottom: 56px;
}

.pagination-link,
.pagination-ellipsis,
.page-numbers {
    width: double($base-spacing-unit);
    height: double($base-spacing-unit);
    line-height: double($base-spacing-unit);
    display: block;
    text-align: center;
    border: 2px solid transparent;
    color: $colour-secondary;

    &.is-active,
    &.current {
        border-color: $colour-ui-light;
        color: $colour-ui-dark;
    }

    @include media-query(palm) {
        display: none;
    }
}

.pagination-prev, .prev,
.pagination-next, .next {
    width: 25%;
    text-align: center;
    color: $colour-ui-dark;

    &.is-disabled {
        opacity: 0.32;
    }

    @include media-query(palm) {
        display: block;
        width: 50%;
    }
}

.pagination-prev, .prev {
    margin-right: auto;

    &::before {
        content: "\00AB" " ";
    }
}

.pagination-next, .next {
    margin-left: auto;

    &::after {
        content: " " "\00BB";
    }
}
