/*------------------------------------*\
    $SOCIAL
\*------------------------------------*/

.social-item {
    display: inline-block;

    &:not(:last-child) {
        margin-right: halve($base-spacing-unit);
    }
}

.social-link {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 100px;
    position: relative;
    transition: background-color $base-anim-speed linear;

    .icon {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &,
    &:hover,
    &:focus,
    &:active {
        color: $colour-ui-light;
    }
}

.social-facebook {
    background-color: #3b5999;

    &:hover,
    &:focus,
    &:active {
        background-color: shade(#3b5999, 60%);
    }
}

.social-twitter {
    background-color: #55acee;

    &:hover,
    &:focus,
    &:active {
        background-color: shade(#55acee, 60%);
    }
}

.social-twitter-x {
    background-color: #000;
    &:hover,
    &:focus,
    &:active {
        background-color: shade(#000, 60%);
    }
}

.social-linkedin {
    background-color: #0077b5;

    &:hover,
    &:focus,
    &:active {
        background-color: shade(#0077b5, 60%);
    }
}

.social-google {
    background-color: #dd4b39;

    &:hover,
    &:focus,
    &:active {
        background-color: shade(#dd4b39, 60%);
    }
}

.social-youtube {
    background-color: #e72e25;

    &:hover,
    &:focus,
    &:active {
        background-color: shade(#e72e25, 60%);
    }
}

