/*------------------------------------*\
    $VIDEO
\*------------------------------------*/

.video {
    position: relative;
    padding-bottom: 56.25%;
    margin-bottom: $base-spacing-unit;

    iframe,
    embed,
    object {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }


}

.video-block{
    @include media-query(palm){
        // height: 300px;
        iframe{
            // height: 200px;
        }
    }
}
