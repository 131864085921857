.products {
    .products-slider {
        display: none;
        &.is-active-custom {
            display: block;
        }
    }

    $radius: 60px;

    &__title {
        font-size: 32px;
    }
    &__slides {
        position: relative;

        .slide {
            &.js-is-active {
                display: flex;
            }

            &.js-is-not-active {
                visibility: hidden;
                display: none;
            }

            div {
                @include media-query(narrow) {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }

        li {
            // padding: 0 150px;
        }

        .slide-title {
            font-size: 32px;
        }

        p {
            font-size: 18px;
            width: 80%;
        }

        picture {
            img {
                display: block;
                margin: 0 auto;
            }
        }
    }

    &__arrow {
        @include media-query(palm) {
            display: none;
        }
        position: absolute;
        border: 2px solid $colour-primary;
        padding: $base-spacing-unit;
        border-radius: 50%;
        svg {
            fill: $colour-primary;
            color: $colour-primary;
        }
        &.right {
            top: 50%;
            right: 0;

            transform: translate(100%, -50%);
            @include media-query(portable) {
                transform: translate(150%);
            }
        }
        &.left {
            top: 50%;
            left: 0;
            -ms-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%);

            @include media-query(portable) {
                transform: translate(-150%);
            }
            svg {
                transform: rotate(180deg);
            }
        }
    }

    &__tabs {
        border-radius: $radius;
        border: 2px solid $colour-primary;
        height: $base-spacing-unit * 3;
        color: $colour-ui-dark;

        &__tab {
            &.is-active {
                background-color: $colour-primary;
                color: #fff;
            }
            cursor: pointer;
            font-size: 18px;
            text-align: center;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &:first-child {
                border-top-left-radius: $radius;
                border-bottom-left-radius: $radius;
            }

            &:last-child {
                border-top-right-radius: $radius;
                border-bottom-right-radius: $radius;
            }

            &:not(:last-child) {
                border-right: 2px solid $colour-primary;
            }

            @include media-query(portable) {
                font-size: 12px;
                flex-direction: column;
            }
        }
    }
}
