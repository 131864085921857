/*------------------------------------*\
    $LIST-BARE
\*------------------------------------*/

// Define reusable mixin
@mixin list-bare {
    list-style: none;
    margin-left: 0;
}


/**
 * Unstyle lists
 */

.list-bare {
    @include list-bare;
}
