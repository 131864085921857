.video-block {
    div {
        height: 800px;

        @include media-query(narrow) {
            height: 500px;
        }
    }
}

