/*------------------------------------*\
    $NOTICE
\*------------------------------------*/

.notice {
    @include font-size(16px, (36 / 20));
    position: fixed;
    top: 100%;
    left: 0;
    right: 0;
    background-color: $colour-primary;
    color: #fff;
    padding: halve($base-spacing-unit) treble($base-spacing-unit);
    transition: transform $base-anim-speed ease-in;
    text-align: center;
    z-index: 10;
    
    > :last-child {
        margin-bottom: 0;
    }
    
    &.is-active {
        transform: translateY(-100%);
        transition: transform $base-anim-speed * 4 ease-out;
    }
    
    @include media-query(lap-and-up) {
        @include font-size(20px, 36 / 20);
    }
}

.notice-close {
    @include btn-bare;
    width: 48px;
    height: 60px;
    position: absolute;
    top: 0;
    right: 0;
    color: rgba(#fff, .75);
    transition: color $base-anim-speed;
    
    &:hover {
        color: #fff;
    }
    
    &::before {
        content: "\00D7";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 40px;
        line-height: 1;
    }
}

.notice-body {
    max-width: 1300px;
    margin: 0 auto;
}

.notice-quote {
    position: relative;

    &::before,
    &::after {
        position: absolute;
        font-size: 66px;
        line-height: 1;
        color: rgba(#fff, .25);
        font-family: georgia;
        margin-top: -6px;
    }
    
    &::before {
        content: "\201C";
        right: 100%;
    }
    
    &::after {
        content: "\201D"
    }
}
