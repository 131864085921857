/*------------------------------------*\
    $AJAX
\*------------------------------------*/

@keyframes rotate {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.ajax {
    position: relative;

    &::before,
    &::after {
        display: block;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        z-index: 2;
        transition: opacity $base-anim-speed linear, visibility 0s $base-anim-speed;
    }

    &::before {
        content: "";
        top: 0;
        left: 0;
        width: calc(100% + 7px);
        height: 100%;
        background-color: rgba($colour-ui-light, .66);
    }

    &::after {
        content: url(../img/loader.svg);
        width: 50px;
        height: 50px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: rotate 0.6s infinite linear;
    }

    &.is-loading {
        &::before,
        &::after {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
        }
    }
}
