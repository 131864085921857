/*------------------------------------*\
    $FEATURE
\*------------------------------------*/

.feature-list {
    @include media-query(palm) {
        margin-bottom: 32px - halve($base-spacing-unit);
    }
}

.feature-list {
    color: inherit;
    display: flex;
    align-items: center;
    text-align: center;

    @include media-query(palm) {
        margin-bottom: double($base-spacing-unit);
    }

    @include media-query(lap-and-up) {
        display: block;
        text-align: center;

        &:hover,
        &:focus,
        &:active {
            color: inherit;

            .feature-title {
                color: $colour-primary;
            }

            .feature-figure {
                background-color: $colour-primary;
                color: #fff;
            }
        }
    }
}

.feature-quaternary {
    .feature-figure {
        color: $colour-quaternary;
        border-color: $colour-quaternary;
    }

    @include media-query(lap-and-up) {
        &:hover,
        &:focus,
        &:active {
            .feature-title {
                color: $colour-quaternary;
            }

            .feature-figure {
                background-color: $colour-quaternary;
            }
        }
    }
}

.feature-figure {
    display: inline-block;
    width: 96px;
    height: 96px;
    position: relative;
    color: $colour-primary;
    border: 2px solid $colour-primary;
    border-radius: 999px;
    transition: background-color $base-anim-speed linear,
        color $base-anim-speed linear;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @include media-query(palm) {
        // margin-right: 16px;
    }

    @include media-query(lap-and-up) {
        width: 94px;
        height: 94px;

        .icon {
            width: 48px;
            height: 48px;
        }
    }
}

.feature-body {
    flex: 1 1 0%;
}

.feature-title {
    transition: color $base-anim-speed linear;

    @include media-query(palm) {
        margin-bottom: 0;
    }
}

.feature-description {
    color: $colour-ui-dark;
}
