/*------------------------------------*\
    $LEAD
\*------------------------------------*/

.lead {
    padding: 40px $base-spacing-unit 1px;
    background: rgba($colour-primary, 1) url(../img/lead.jpg) right center no-repeat;
    background-size: cover;
    color: $colour-ui-light;
    position: relative;

    @include media-query(portable) {
        &::before {
            content: "";
            display: block;
            border-top: 1px solid rgba($colour-ui-light, .12);
            margin-bottom: $base-spacing-unit;
        }
    }

    @include media-query(desk) {
        margin-left: -32px;
        padding: ($base-spacing-unit + 32px) $base-spacing-unit 32px ($base-spacing-unit + 32px);
    }
}

.lead-breadcrumbs,
.lead-share,
.lead-dropdown {
    @include media-query(portable) {
        display: none;
    }
}

.lead-breadcrumbs {
    border-bottom: 1px solid rgba($colour-ui-light, .12);
    margin-left: 0 - (32px + $base-spacing-unit);
    padding-left: 32px + $base-spacing-unit;
}

    .lead-breadcrumbs-item {
        display: inline-block;

        &:not(:last-child)::after {
            content: "\00BB";
            margin-left: 2px;
            margin-right: 2px;
        }
    }

    .lead-breadcrumbs-item,
    .lead-breadcrumbs-link {
        color: rgba($colour-ui-light, .48);
    }

    .lead-breadcrumbs-link {
        &.is-active,
        &:hover,
        &:focus,
        &:active {
            color: $colour-ui-light;
        }
    }

.lead-share {
    @include btn-bare;
    position: absolute;
    background-color: $colour-primary;
    transition: background-color $base-anim-speed;
    left: 44px;
    bottom: -28px;
    padding: 16px 56px 16px 22px;
    
    .icon {
        position: absolute;
        top: 50%;
        right: 28px;
        transform: translate(50%, -50%);
    }
    
    &:hover,
    &:focus,
    &:active {
        background-color: $colour-primary-hover;
    }
}

.lead-title {
    color: $colour-ui-light;

    @include media-query(desk) {
        @include font-size(48px, false);
    }
}

.lead-dropdown {
    min-width: 320px;
    position: relative;
}

.lead-dropdown-btn {
    @include btn-bare;
    text-align: left;
    border-radius: 0;
    width: 100%;
    padding: 10px $base-spacing-unit;
    border: 2px solid rgba($colour-ui-light, .32);
    transition: background-color $base-anim-speed linear, color $base-anim-speed linear, border-color $base-anim-speed linear;

    &.is-active {
        color: $colour-secondary;
        background-color: rgba($colour-ui-light, .9);
        border-color: rgba($colour-ui-dark, .12);
    }
}

.lead-dropdown-list {
    margin-bottom: 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity $base-anim-speed linear, visibility 0s $base-anim-speed;
    border: 2px solid rgba($colour-ui-dark, .12);//rgba($colour-ui-light, .32);
    border-top: none;
    background-color: rgba($colour-ui-light, .9);
    box-shadow: 0 2px 0 rgba($colour-ui-dark, .32);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    z-index: 1;

    &.is-active {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
    }
}

.lead-dropdown-link {
    padding: 10px $base-spacing-unit;
    display: block;
    color: $colour-secondary;
    border-top: 2px solid rgba($colour-ui-dark, .12);

    :first-child > & {
        border-top: none;
    }

    &.is-active {
        color: $colour-ui-dark;
    }

    &:hover,
    &:focus {
        color: $colour-primary;
    }
}

.lead-nav {
    @include media-query(portable) {
        margin: (0 - $base-spacing-unit) (0 - $base-spacing-unit) $base-spacing-unit;
    }
}

.lead-nav-item {
    @include media-query(portable) {
        margin-left: 0 !important;
        width: 100% !important;
    }
}

.lead-nav-link {
    background-color: $colour-primary;
    color: $colour-ui-light;
    display: flex;
    align-items: center;
    @include font-size(14px);
    font-weight: 500;
    padding: 20px 21px;
    transition: background-color $base-anim-speed linear;

    &:hover,
    &:focus,
    &:active {
        background-color: #e86400 !important;
        color: $colour-ui-light;
    }

    @include media-query(portable) {
        :nth-child(1) > & {
            background-color: #d26f21;
        }

        :nth-child(2) > & {
            background-color: #e17723;
        }
    }

    @include media-query(desk) {
        border-radius: 99px;
        padding: halve($base-spacing-unit) 18px halve($base-spacing-unit) 30px;
        margin-bottom: $base-spacing-unit;
    }
}

.lead-nav-title {
    flex: 1 1 0;
    margin-right: 16px;

    @supports (-ms-accelerator:true) {
       flex: 1;
    }

    @supports (-ms-ime-align:auto) and (color:unset) {
        flex: 1;
    }

    @include media-query(portable) {
        margin-left: 16px;
    }
}

.lead-nav-icon {
    @include media-query(desk) {
        display: none;
    }
}
