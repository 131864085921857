/*------------------------------------*\
    $TABS
\*------------------------------------*/

.tabs-list {
    display: flex;
    margin-bottom: 0;

    @include media-query(desk) {
        display: none;
    }
}

    .tabs-item {
        flex: 1 1 0%;

        &:nth-child(2) .tabs-link {
            background-color: $colour-secondary-2;
        }

        &:nth-child(3) .tabs-link {
            background-color: $colour-secondary-3;
        }
    }

    .tabs-link {
        display: block;
        background-color: $colour-secondary;
        color: rgba($colour-ui-light, .64);
        text-align: center;
        padding: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover,
        &:focus,
        &:active,
        &.is-active {
            color: $colour-ui-light;
        }

        &.is-active {
            font-weight: 500;
        }
    }

@include media-query(portable) {
    .tabs-panel {
        display: none;
        background-color: $colour-secondary;
        color: rgba($colour-ui-light, .64);
        padding: $base-spacing-unit $base-spacing-unit 1px;

        &:nth-child(2) {
            background-color: $colour-secondary-2;
        }

        &:nth-child(3) {
            background-color: $colour-secondary-3;
        }

        h1, h2, h3, h4, h5, h6, a {
            color: $colour-ui-light;
        }

        &.is-active {
            display: block;
        }

        .btn {
            color: $colour-ui-light;
            border-color: rgba($colour-ui-light, .24);

            &:hover,
            &:focus,
            &:active {
                background-color: $colour-ui-light;
                color: $colour-secondary;
            }
        }

        .link-bare {
            &:hover,
            &:focus,
            &:active {
                color: $colour-primary;
            }
        }
    }
}
