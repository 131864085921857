/*------------------------------------*\
    $SUBNAV
\*------------------------------------*/

.subnav {
    @include media-query(portable) {
        transition: background-color $base-anim-speed linear;

        &.is-active {
            background-color: rgba($colour-secondary, .64);

            .nav-link {
                color: $colour-primary;
            }
        }
    }

    @include media-query(desk) {
        .nav-link {
            &::after {
                content: "";
                display: inline-block;
                vertical-align: middle;
                width: 8px;
                height: 8px;
                border-right: 2px solid $colour-secondary;
                border-bottom: 2px solid $colour-secondary;
                transform: translateY(-2px) rotate(45deg);
                margin-left: 8px;
            }
        }

        &:hover {
            .subnav-list {
                opacity: 1;
                visibility: visible;
                transition-delay: 0s;
            }

            .nav-link {
                color: $colour-primary;
            }
        }
    }
}

.subnav-list {
    @include media-query(portable) {
        display: none;
        margin: 0 $base-spacing-unit;
    }

    @include media-query(desk) {
        display: block !important;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 100%;
        left: -1px;
        margin-top: 3px;
        background-color: rgba($colour-secondary, .88);
        border-radius: 5px;
        min-width: calc(100% + 2px);
        transition: opacity $base-anim-speed linear, visibility 0s $base-anim-speed;

        &::before {
            content: "";
            position: absolute;
            left: calc(100vw / 18); // half of 9 nav items
            bottom: 100%;
            border: 5px solid transparent;
            border-bottom-color: rgba($colour-secondary, .88);
        }
    }
}

.subnav-toggle {
    @include btn-bare;
    display: inline-block;
    outline: none;

    &::after {
        content: "";
        width: 8px;
        height: 8px;
        border-right: 2px solid $colour-ui-light;
        border-bottom: 2px solid $colour-ui-light;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        transition: transform $base-anim-speed ease-in-out, border-color $base-anim-speed linear;
    }

    &.is-active::after {
        transform: translate(-50%, -50%) rotate(225deg);
        border-color: $colour-primary;
    }

    @include media-query(portable) {
        position: absolute;
        width: 58px;
        height: 58px;
        top: 0;
        right: 0;
    }

    @include media-query(desk) {
        display: none;
    }
}

.subnav-item {
    border-top: 1px solid rgba($colour-ui-light, .06);

    @include media-query(desk) {
        border-top-color: rgba($colour-secondary, .88);

        &:first-child {
            border-top: none;
        }
    }
}

.subnav-link {
    display: block;
    color: rgba($colour-ui-light, .64);
    padding: 10px 0;

    &:hover,
    &:focus,
    &:active {
        color: $colour-ui-light;
    }

    @include media-query(desk) {
        padding: 16px $base-spacing-unit;
        white-space: nowrap;
        color: $colour-ui-light;
        transition: background-color halve($base-anim-speed) linear;

        &:hover,
        &:focus,
        &:active {
            background-color: rgba($colour-secondary, .5);
        }
    }

    :first-child > & {
        border-radius: 5px 5px 0 0;
    }

    :last-child > & {
        border-radius: 0 0 5px 5px;
    }
}
