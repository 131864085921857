/*------------------------------------*\
    $LISTS
\*------------------------------------*/

/**
 * Remove margin from nested lists
 */

li {
    list-style: none;

    > ul,
    > ol {
        margin-bottom: 0;
    }
}
