/*------------------------------------*\
    $HERO
\*------------------------------------*/

.hero,
.hero-title {
    color: $colour-ui-light;
}

.hero {
    height: 50vh;
    background-size: cover;
    background-position: center center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    // padding: $base-spacing-unit $base-spacing-unit 0;
    overflow: hidden;

    .hero-title {
        color: $colour-ui-light;
        text-align: center;
        letter-spacing: 3px;
    }

    @include media-query(desk) {
        // height: calc(100vh - 220px); // full height minus height of header
        height: calc(100vh - 350px);
        min-height: 500px;

        &.small {
            height: calc(100vh - 500px);
            min-height: 500px;
        }
    }

    &__description {
        font-size: $base-font-size + 2px;
        width: 40%;
        font-weight: normal;

        @include media-query(portable) {
            width: 90%;
        }
    }

    &__large-title {
        font-size: $base-font-size * 5;
        text-transform: uppercase;

        @include media-query(portable) {
            font-size: 42px;
        }
    }

    &__hero-list-title {
        background-color: $colour-primary;
        color: #fff;
        margin-bottom: 0;
        padding: halve($base-spacing-unit);
        font-size: 18px;
        &:hover {
        }

        @include media-query("1500") {
            font-size: 12px;
        }
    }

    .hero-content-wrapper {
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        opacity: 1;
        visibility: visible;
        transition: all .6s ease-in-out;

        .dark-layer {
            z-index: 2;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            pointer-events: none;
        }

        &.hidden {
            opacity: 0;
            visibility: hidden;
            z-index: -3;
        }

        .image-wrapper {
            z-index: -2;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            width: 100%;
            height: 100%;
        }

        .hero-bg {
            z-index: 1;
            width: 100%;
            height: 100% !important;
            object-fit: cover;
            font-family: 'object-fit:cover;';
        }
    }

    .hero-content {
        position: relative;
        z-index: 2;

        &.flex-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .hero-title-new {
            @include media-query(portable) {
                font-size: $base-font-size * 2 !important;
            }
        }
    }

    &__hero-list-image {
        width: 100%;
        height: 200px;
        display: block;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__hero-list-top-arrow {
        display: none;
        position: absolute;
        background-color: $colour-primary;
        top: -35%;
        // left: 30%;
        transform: translateX(-50%);
        $size: 0;
        width: $size;
        height: $size;

        @include media-query(ten-eighty-only) {
            left: 23%;
        }

        .triangle {
            display: none;
            fill: $colour-primary;
            stroke: $colour-primary;
            stroke-width: 10;
        }
    }

    &__hero-list-item {
        position: relative;
        flex: 1;
        height: 245px;
        &:hover {
            cursor: pointer;
            .hero__hero-list-title {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .triangle-svg {
                display: block;
            }

            .hero__hero-list-top-arrow {
                display: none;

                .triangle {
                    display: block;

                    @include media-query(ultra-wide) {
                        display: none;
                    }
                }

                @include media-query(ultra-wide) {
                    display: none;
                }
            }
        }

        @include media-query(narrow) {
            flex: none;
        }

        a {
            display: block;
            height: 245px;
        }
    }
}

.hero-title {
    // text-transform: uppercase;
    @include font-size(42px, (39px / 32px));
    font-weight: bold;

    @include media-query(desk) {
        @include font-size(48px, 1.25);
    }

    @include media-query(palm) {
        font-size: 28px;
    }
}

.hero-title-list-item {
    text-transform: uppercase;
    text-align: left !important;
    @include media-query(desk) {
        @include font-size(64px, 1.25);
    }
}

.hero-title-em {
    color: $colour-primary;
}

.hero-list {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 16px;
    background: $colour-secondary;

    @include media-query(desk) {
        padding: 0;
        padding-top: $base-spacing-unit;
        padding-top: ($base-spacing-unit / $base-font-size) * 1rem;
        padding-bottom: $base-spacing-unit;
        padding-bottom: ($base-spacing-unit / $base-font-size) * 1rem;
    }
}

$hero-fade-duration: (0.6s + 3.4s) * 4;

@keyframes fade {
    1.875%,
    23.125% {
        opacity: 1;
    }

    0%,
    25%,
    100% {
        opacity: 0;
    }
}

/*
    .hero-list-item {
        @include media-query(portable) {
            opacity: 0;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;

            &:nth-child(1) {
                animation: fade $hero-fade-duration 0s infinite;
            }

            &:nth-child(2) {
                animation: fade $hero-fade-duration 4s infinite;
            }

            &:nth-child(3) {
                animation: fade $hero-fade-duration 8s infinite;
            }

            &:nth-child(4) {
                animation: fade $hero-fade-duration 12s infinite;
            }
        }

        @include media-query(desk) {
            width: 128px;
            height: 128px;
            background-color: rgba($colour-secondary, .66);
            border: 2px solid $colour-ui-light;
            border-radius: 999px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: $base-spacing-unit halve($base-spacing-unit) 0;
            line-height: (20px / 14px);

            &:not(:last-child) {
                margin-right: 32px;
            }
        }
    }
    */

.hero-list-item {
    position: relative;
    margin: 16px;
    width: 128px;
    height: 128px;
    background-color: #fff;
    border-radius: 999px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $base-spacing-unit halve($base-spacing-unit) 0;
    line-height: (20px / 14px);
    text-align: center;

    @include media-query(desk) {
        margin: 0;

        &:not(:last-child) {
            margin-right: 32px;
        }
    }

    a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 999px;
    }
}

.hero-list-em {
    color: $colour-primary;
    font-weight: 500;
}

.hero-link {
    display: flex;
    align-items: center;
    @include font-size(16px);
    font-weight: 500;

    &,
    &:hover,
    &:focus,
    &:active {
        color: $colour-ui-light;
    }

    &:hover,
    &:focus,
    &:active {
        span {
            text-decoration: underline;
        }
    }

    .icon {
        margin-right: 16px;
    }

    > span {
        flex: 1;
    }
}

.hero-link-em {
    color: $colour-primary;
}

.hero-link_full {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    padding: 11px 9px 14px 20px;
    @include font-size(16px);
    font-weight: 500;
    background: $colour-secondary;
    border: 2px solid #fff;
    border-radius: 8px;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        width: 73px;
        background: rgba(#000, 0.24);
        transition-duration: 300ms;
        transition-timing-function: ease-in-out;
    }

    span {
        width: 240px;
        color: #fff;
        text-align: left;
        transition-duration: 300ms;
        transition-timing-function: ease-in-out;
    }

    .icon {
        fill: #fff;
        position: relative;
        z-index: 2;
        padding-top: 3px;
        transition-duration: 300ms;
        transition-timing-function: ease-in-out;
    }

    &:hover,
    &:focus,
    &:active {
        &:before {
            width: 100%;
        }
        span {
            color: $colour-primary;
        }
        .icon {
            fill: $colour-primary;
        }
    }
}

.layout_split {
    padding: 0;
    background: #9fadb3;

    .list_brochures {
        display: flex;
        align-items: center;
        justify-content: center;

        .list_brochures_wrapper {
            padding: 24px 24px 0;
        }

        .heading {
            padding-bottom: 12px;
            color: #fff;
        }
        ul li a {
            margin-top: 12px;
            width: 100%;
            color: #fff;
            border-color: $colour-primary;

            &:hover,
            &:focus,
            &:active {
                background-color: $colour-primary;
            }
        }
    }

    .video {
        display: flex;
        margin-bottom: 0;
        height: 100%;
    }
}

#hero-list {
    .splide__pagination__page {
        background-color: $colour-primary;

        &.is-active {
            border: 2px solid $colour-primary;
            box-sizing: border-box;
            background-color: transparent;
            height: 8px;
            width: 8px;
        }
    }
    .splide__pagination {
        bottom: -2.5rem !important;
    }

    .splide__track {
        overflow: initial;
    }
}

.hero-list-title {
    text-align: left !important;
    color: $colour-ui-light;
    text-transform: uppercase;
    font-size: $base-font-size * 5;

    @include media-query("1500") {
        font-size: $base-font-size;
    }
}

.hero-list-description {
    text-align: left;
    font-size: 18px;
    font-weight: normal;

    @include media-query(portable) {
        font-size: $base-font-size;
    }

    @include media-query("1500") {
        font-size: $base-font-size;
    }
}

.hero-title-new {
    a {
        font-size: $base-font-size;
        border-color: $colour-ui-light !important;
        text-align: left !important;
    }
}

.triangle-svg {
    position: absolute;
    top: -28px;
    left: 50%;
    width: 32px;
    height: 32px;
    fill: #ef7e25;
    transform: translateX(-50%);
    display: none;
    width: 32px;
    height: 32px;
}
