/*------------------------------------*\
    $CONTAINER
\*------------------------------------*/

// Setup widths and gutters for containers
$container-gutter: $base-spacing-unit !default;
$container-width: 960px !default;

/**
 * Contain elements in an arbitrary width
 */

.container {
    max-width: $container-width;
    margin: 0 auto;
    width: 100%;

    &.container-left {
        margin: 0;
        margin-left: auto;

        &.container-xxl {
            max-width: 1930px;
        }
    }
}

/**
 * Size modifiers
 */

.container-xs {
    max-width: 600px;

    @include media-query(portable) {
        max-width: 80%;
    }
}

.container-sml {
    max-width: 768px;
}

.container-med {
    max-width: 880px;
}

.container-lrg {
    max-width: 1300px;
}

.container-xxl {
    max-width: 1620px;
}

.container-80 {
    max-width: 80%;
}

@include media-query(narrow) {
    .container-md {
        max-width: $container-width;
        margin: 0 auto;
        width: 100%;

        &.container-left-md {
            margin: 0;
            margin-left: auto;

            &.container-xxl-md {
                max-width: 1930px;
            }
        }

        &.container-normal-md {
            margin: 0 auto;
        }
    }

    .container-none-md {
        max-width: 100%;
    }
}

