/*------------------------------------*\
    $WIDTHS
\*------------------------------------*/

/**
 * Widths
 */

$breakpoint-widths: (
    '' : (
        '1/2': 1/2
    ),
    'palm' : (
    ),
    'portable' : (
    ),
    'lap' : (
        '1/2': 1/2
    ),
    'lap-and-up': (
        '1/2': 1/2
    ),
    'desk': (
        'auto': auto,
        '1/4': 1/4,
        '1/3': 1/3,
        '1/2': 1/2,
        '2/3': 2/3,
        '3/4': 3/4
    ),
    'wide': (
        '1/6': 1/6,
        '1/4': 1/4,
        '1/3': 1/3,
        '2/5': 2/5,
        '1/2': 1/2,
        '3/5': 3/5,
        '3/4': 3/4
    )
);

@each $breakpoint, $widths in $breakpoint-widths {
    @if ($breakpoint != '') {

        // Wrap a media query around it if we've included a breakpoint
        @include media-query($breakpoint) {
            @each $width, $fraction in $widths {
                [class~="#{$width}-#{$breakpoint}"] {
                    @include grid-width($fraction);

                    .grid-flush > & {
                        @include grid-width($fraction, 0);
                    }

                    .grid-sml > & {
                        @include grid-width($fraction, 6px);
                    }

                    .grid-med > & {
                        @include grid-width($fraction, 32px);
                    }

                    @if ($breakpoint == 'wide') {
                        .grid-lrg > & {
                            @include grid-width($fraction, 64px);
                        }
                    }
                }
            }
        }

    } @else {

        // If not, just spit out the classes
        @each $width, $fraction in $widths {
            [class~="#{$width}"] {
                @include grid-width($fraction);

                .grid-flush > & {
                    @include grid-width($fraction, 0);
                }

                .grid-sml > & {
                    @include grid-width($fraction, 6px);
                }

                .grid-med > & {
                    @include grid-width($fraction, 32px);
                }
            }
        }

    }
}
