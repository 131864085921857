.text-and-image {
    picture {
        width: 100%;
        flex-grow: 1;
        align-self: stretch;
        img {
            width: 100%;
            height: 550px;
            object-fit: cover;
        }
    }

    &__content {
        background-color: #fff;
    }

    &__header {
        padding-bottom: 30px;
        position: relative;
        width: fit-content;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 75%;
            border-bottom: 2px solid $colour-primary;
        }
    }

    @include media-query(palm) {
        picture {
            width: 100%;
            img {
                width: 100%;
                height: 300px;
                object-fit: cover;
            }
        }
    }

    @include media-query("1400") {
        picture {
            width: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

