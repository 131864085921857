/*------------------------------------*\
    $LINK
\*------------------------------------*/

.link-quaternary {
    color: $colour-quaternary;

    &:hover,
    &:focus,
    &:active {
        color: #7cba00;
    }
}
