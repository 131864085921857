/*------------------------------------*\
    $FIGURE
\*------------------------------------*/

.figure {
    display: block;
    margin-bottom: $base-spacing-unit;
}

.figure-full {
    width: 100%;
}

.figure-center {
    margin-left: auto;
    margin-right: auto;
}
