/*------------------------------------*\
    $ACCESSIBILITY
\*------------------------------------*/

// Define reusable mixin
@mixin visuallyhidden {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}


/**
 * Styles for screen-reader only elements
 */

.visuallyhidden, %visuallyhidden {
    @include visuallyhidden;
}
