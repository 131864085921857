/*------------------------------------*\
    $POST
\*------------------------------------*/

.post {
    @include media-query(desk) {
        display: flex;
        align-items: center;
        color: $colour-secondary;

        .no-flexbox & {
            display: block;
        }
    }
}

.post-figure {
    display: block;
    position: relative;

    img {
        margin-bottom: halve($base-spacing-unit);
    }

    &:hover,
    &:focus,
    &:active {
        .post-arrow {
            background-color: $colour-secondary;
        }
    }

    @include media-query(desk) {
        margin-bottom: $base-spacing-unit;
        margin-right: $base-spacing-unit;
        max-width: percentage(1/3);

        &,
        img {
            border-radius: 999px;
        }

        img {
            margin-bottom: 0;
        }

        .no-flexbox & {
            float: left;
        }
    }
}

.post-arrow {
    right: -3px;
    bottom: -3px;

    @include media-query(portable) {
        display: none;
    }
}

.post-body {
    flex: 1 1 0%;
    margin-bottom: $base-spacing-unit;

    > :last-child {
        margin-bottom: 0;
    }

    .no-flexbox & {
        overflow: hidden;
    }
}

.post-meta,
.post-title {
    margin-bottom: halve($base-spacing-unit);
}
