.text-content {
    ul {
        li {
            padding-left: 20px;
            text-indent: -20px;

            &:before {
                content: "";
                margin-right: 12px;
                display: inline-block;
                width: 8px;
                height: 8px;
                border: 2px solid #ef7e25;
                border-radius: 100px;
            }
        }
    }
}

