/*------------------------------------*\
    $GALLERY
\*------------------------------------*/

.gallery-tabs {
    display: flex;
}

    .gallery-tabs-item {
        flex: 1 1 0%;
    }

    .gallery-tabs-link {
        display: block;
        text-align: center;
        color: rgba($colour-secondary, .48);
        border: 2px solid $colour-ui-light;
        border-left: none;
        padding: 10px;

        &.is-active {
            background-color: $colour-ui-light;
            color: $colour-primary;
            font-weight: 500;
        }

        :first-child > & {
            border-left: 2px solid $colour-ui-light;
            border-radius: 4px 0 0 4px;
        }

        :last-child > & {
            border-radius: 0 4px 4px 0;
        }
    }

.gallery-panel {
    display: none;

    &.is-active {
        display: block;
    }
}

.gallery-wrapper {
    margin-left: -56px;
    margin-right: -56px;
}

.gallery-slider {
    .slick-arrow {
        @include btn-bare;
        position: absolute;
        top: 50%;
        width: double($base-spacing-unit);
        height: double($base-spacing-unit);
        border-right: 2px solid rgba($colour-secondary, .16);
        border-bottom: 2px solid rgba($colour-secondary, .16);
        transition: border-color $base-anim-speed linear;
        overflow: hidden;
        text-indent: 100%;
        z-index: 1;

        @media screen and (min-width: 1100px) {
            width: $base-spacing-unit * 4;
            height: $base-spacing-unit * 4;
        }

        &:hover {
            border-color: $colour-secondary;
        }
    }

    .slick-prev {
        right: calc(50% + 384px);
        transform: translateY(-50%) rotate(135deg);
    }

    .slick-next {
        left: calc(50% + 384px);
        transform: translateY(-50%) rotate(-45deg);
    }
}

.gallery-slider-grid {
    min-height: 525px;
}
