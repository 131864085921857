/*------------------------------------*\
    $RULES
\*------------------------------------*/

hr {
    border: none;
    border-bottom: 1px solid $colour-ui;
    margin-left: -$base-spacing-unit;
    margin-right: -$base-spacing-unit;
}
