/*------------------------------------*\
    $BOARD
\*------------------------------------*/

.board {
    display: flex;
    flex-direction: column;
}

    .board-element {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    