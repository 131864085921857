/*------------------------------------*\
    $LANDMARK
\*------------------------------------*/

/**
 * Add additional vertical margin
 */

@include media-query(desk) {
    .landmark-desk {
        margin-bottom: double($base-spacing-unit);
    }
}
