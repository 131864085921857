/*------------------------------------*\
    $WIDGET
\*------------------------------------*/

.widget {
    .slick-list {
        overflow: visible;
    }
}

    .widget-header {
        background-color: $colour-secondary;
        color: $colour-ui-light;
        padding: $base-spacing-unit double($base-spacing-unit) $base-spacing-unit $base-spacing-unit;
        margin-bottom: 0;

        a {
            &:hover,
            &:focus,
            &:active {
                color: rgba($colour-ui-light, .48);
            }
        }
    }

    .widget-header-tertiary {
        background-color: $colour-tertiary;
    }
