/*------------------------------------*\
    $ICON
\*------------------------------------*/

/**
 * Icon object - used with <svg> icons
 */

.icon {
    fill: currentColor;
    vertical-align: middle;
    display: inline-block;
    width: 18px;
    height: 18px;
}

.icon-xs {
    width: 10px;
    height: 10px;
}

.icon-sml {
    width: 14px;
    height: 14px;
}

.icon-med {
    width: $base-spacing-unit;
    height: $base-spacing-unit;
}

.icon-lrg {
    width: double($base-spacing-unit);
    height: double($base-spacing-unit);
}
