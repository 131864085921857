.newsletter {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .description {
        @include media-query(portable) {
            font-size: $base-font-size;
        }
    }

    .left-column {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        @include media-query(portable) {
            font-size: $base-font-size * 3;
            padding-bottom: $base-spacing-unit;
        }

        @include media-query(narrow) {
            justify-content: center;
        }

        @include media-query("1500"){
            text-align: center;
            padding-bottom: $base-spacing-unit * 2;
        }

        p {
            font-weight: bold;
            color: #fff;
            width: 50%;
            font-size: 36px;
            padding-bottom: 0;
            margin-bottom: 0;

            @include media-query(narrow) {
                text-align: center;
                width: 80% !important;
            }
        }
    }

    .right-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #fff;
        padding: 90px 180px;

        @include media-query("1500") {
            padding: 45px 90px;
        }

        @include media-query(palm) {
            padding: 25px 45px;
        }

        h3 {
            font-size: 24px;
            margin-bottom: 10px;

            @include media-query(palm) {
                font-size: 18px;
            }
        }

        p {
            margin-bottom: 20px;
            font-size: 16px;

            @include media-query(palm) {
                font-size: 14px;
            }
        }

        form {
            display: flex;
            align-items: center;
            margin-top: 40px;
            position: relative;

            input[type="email"] {
                padding: 15px;
                padding-left: 0;
                width: 100%;
                border: none;
                background-color: transparent;
                border-bottom: 2px solid $colour-primary;

                &:focus {
                    outline: none;
                }

                &::placeholder {
                    color: $colour-primary;
                    font-size: 18px;
                }
            }

            button {
                @include media-query(palm) {
                    right: -50px;
                    bottom: 0;
                }
                position: absolute;
                right: 0;
                padding: 10px 20px;
                color: #e3e3e3;
                background-color: transparent;
                border: none;
                cursor: pointer;
                font-weight: bold;

                svg {
                    fill: $colour-primary;
                }
            }
        }
    }
}

