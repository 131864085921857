/*------------------------------------*\
    $EVENT
\*------------------------------------*/

.event-figure {
    background-size: cover;
    background-position: center center;
    position: relative;

    @include media-query(lap-and-up) {
        margin-bottom: $base-spacing-unit;

        &:hover,
        &:focus,
        &:active {
            .event-btn {
                background-color: #e86400;
            }
        }
    }

    @include media-query(desk) {
        margin-bottom: 32px;
    }
}

.event-figure img {
    width: 100%;

    @include media-query(lap-and-up) {
        opacity: 0;
        position: absolute;
    }
}

.event-btn {
    position: absolute;
    bottom: -12px;
    right: -12px;
    background-color: $colour-primary;
    color: $colour-ui-light;
    border-radius: 100px;
    padding: halve($base-spacing-unit) 15px halve($base-spacing-unit) 32px;
    font-weight: 500;
    z-index: 1;
    box-shadow: 0 2px 0 rgba($colour-ui-dark, .12);
    transition: background-color $base-anim-speed linear;
}
