/*------------------------------------*\
    $FOOTER
\*------------------------------------*/

.footer {
    padding: 0 $base-spacing-unit;

    @include media-query(portable) {
        text-align: center;
    }

    @include media-query(desk) {
        border-top: 6px solid $colour-ui;
        padding: treble($base-spacing-unit) $base-spacing-unit
            double($base-spacing-unit);
    }

    .badge_10-years {
        margin: 0 auto 24px;
        width: 160px;
        height: 160px;

        @include media-query(desk) {
            display: none;
        }
    }
}

.footer-mille {
    @include font-size(12px, (22px / 12px));

    a {
        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }
}

.footer-logo {
    display: block;
    margin-bottom: halve($base-spacing-unit);
}

.footer-affiliations {
    margin-left: -$base-spacing-unit;
    margin-bottom: 0;
    display: flex;

    > li {
        margin-left: $base-spacing-unit;
        display: inline-block;
        margin-bottom: $base-spacing-unit;
    }

    @include media-query(portable) {
        flex-wrap: wrap;
        justify-content: center;
    }
}

