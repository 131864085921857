/*------------------------------------*\
    $BOX
\*------------------------------------*/

$box-padding: $base-spacing-unit;

/**
 * Box off content
 */

.box {
    margin-bottom: $base-spacing-unit;
    padding: $box-padding $box-padding 1px;
    position: relative;

    @include media-query(desk) {
        padding: 32px 32px (32px - $base-spacing-unit);
        margin-bottom: 32px;
    }
}

.box {
    border: 1px solid $colour-ui;
}

.box-img {
    display: block;
    margin: 0 - ($base-spacing-unit + 1px) 0 - ($base-spacing-unit + 1px) $base-spacing-unit;
	border:1px solid $colour-ui;

    @include media-query(desk) {
        margin: -33px -33px $base-spacing-unit;
    }
}

.box-btn {
    position: absolute;
    right: -10px;
    bottom: -9px;
    border-radius: 100px;
    width: double($base-spacing-unit);
    height: double($base-spacing-unit);
    background-color: $colour-primary;
    color: #fff;
    box-shadow: 0 3px 0 rgba($colour-ui-dark, .12);
    transition: background-color $base-anim-speed;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover,
    &:focus,
    &:active {
        background-color: #e86400;
        color: $colour-ui-light;
    }
}
