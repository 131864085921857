/*------------------------------------*\
    $ADJACENT
\*------------------------------------*/

/**
 * Remove vertical margin
 */

.adjacent {
    margin-bottom: 0;
}
