.awards {
    h2{
        @include media-query(palm){
            margin-top: $base-spacing-unit;
        }
    }
    &__image {
        $size: 214px;
        height: $size;
        width: $size;
        margin-bottom: $base-spacing-unit;
        img {
            height: 100%;
            object-fit: contain;
        }
    }

    &__list {
        margin: $base-spacing-unit * 3 0;

        h4{
            @include media-query("1400"){
                font-size: 16px;
            }
        }
    }

    &__award-heading {
        margin-bottom: 0;
    }
}

