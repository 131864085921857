/*------------------------------------*\
    $IMG-HIDE
\*------------------------------------*/

/**
 * Visually hide images without scaling them to 1 x 1px
 */

.img-hide {
    position: absolute;
    left: -9999px;
    max-width: none;
    opacity: 0;
}
