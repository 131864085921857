/*------------------------------------*\
    $MODAL
\*------------------------------------*/

.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background-color: rgba($colour-secondary, .88);
    transition: opacity $base-anim-speed linear, visibility 0s $base-anim-speed;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    overflow: auto;
    padding: $base-spacing-unit halve($base-spacing-unit);

    @include media-query(lap-and-up) {
        padding: $base-spacing-unit;
    }

    &.is-active {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
        backface-visibility: hidden;
        -webkit-overflow-scrolling: touch;
    }

    &::before {
        content: "";
        vertical-align: middle;
        line-height: 100%;
        height: 100%;
        display: inline-block;
        margin-left: -0.3em;
    }
}

.modal-body {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    background-color: $base-background-colour;
    box-shadow: 0 0 32px rgba(#000, .16);
    padding: $base-spacing-unit $base-spacing-unit 1px;
    max-width: 480px;
    width: 100%;

    @include media-query(lap-and-up) {
        padding: 32px 32px 8px;
    }

    @include media-query(portable) {
        position: relative;
    }
}

    .modal-body-sml {
        max-width: 320px;
    }

    .modal-body-med {
        max-width: 600px;
    }

    .modal-body-lrg {
        max-width: 1200px;
    }

.modal-close {
    @include btn-bare;
    position: absolute;
    border-radius: 100%;
    top: -8px;
    right: -8px;
    z-index: 5;
    width: double($base-spacing-unit);
    height: double($base-spacing-unit);
    background-color: $colour-primary;
    background-clip: content-box;
    color: $colour-ui-light;
    transition: color $base-anim-speed linear, background-color $base-anim-speed;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @include media-query(desk) {
        top: 54px;
        right: 64px;
        width: 64px;
        height: 64px;

        &:hover,
        &:focus,
        &:active {
            background-color: #e86400;
        }
    }

    @include media-query(desk) {
        .modal-contact & {
            background-color: $colour-quaternary;
            right: 144px;

            &:hover,
            &:focus,
            &:active {
                background-color: $colour-quaternary-hover;
            }
        }

        .modal-tel & {
            background-color: $colour-tertiary;
            right: 224px;

            &:hover,
            &:focus,
            &:active {
                background-color: $colour-tertiary-hover;
            }
        }
    }
}

.modal-tel .delta {
    color: $colour-secondary;
}
