/*------------------------------------*\
    $BAND
\*------------------------------------*/

/**
 * Full width, coloured wrappers. Designed to be used around .containers
 */

.band-spacer {
    padding-top: $base-spacing-unit;

    &.double-spacer {
        padding-top: ($base-spacing-unit * 2);

        @include media-query(palm) {
            padding-top: $base-spacing-unit;
        }
    }

    &.triple-spacer {
        padding-top: ($base-spacing-unit * 3);

        @include media-query(palm) {
            padding-top: ($base-spacing-unit * 1.3);
        }
    }

    &.quintuple-spacer {
        padding-top: ($base-spacing-unit * 5);

        @include media-query(palm) {
            padding-top: ($base-spacing-unit * 1.6);
        }
    }
}

.band {
    padding: $base-spacing-unit $base-spacing-unit;

    &.no-bottom {
        padding-bottom: 0 !important;
    }

    &.triple {
        padding: ($base-spacing-unit * 3) ($base-spacing-unit * 3);

        @include media-query(palm) {
            padding: $base-spacing-unit $base-spacing-unit;
        }
    }

    &.five-times {
        padding: ($base-spacing-unit * 5) ($base-spacing-unit * 5);

        @include media-query(palm) {
            padding: ($base-spacing-unit * 1.6) ($base-spacing-unit * 1.6);
        }
    }

    &.four-times {
        padding: ($base-spacing-unit * 4) ($base-spacing-unit * 4);

        @include media-query(portable) {
            padding: ($base-spacing-unit * 2.5) ($base-spacing-unit * 2.5);
        }
    }
}

.band-xx-lrg {
    padding-top: calc(24px * 6);
    padding-bottom: calc(24px * 6);
}

.band-top {
    padding-top: $base-spacing-unit;
}

.band-m-right {
    margin-right: $base-spacing-unit !important;
}

.band-m-right-16 {
    margin-right: ($base-spacing-unit * 0.6666666) !important;
}

.band-m-right-8 {
    margin-right: ($base-spacing-unit * 0.33333) !important;

    @include media-query(palm) {
        margin-right: 0 !important;
    }
}

.band-bottom {
    padding-bottom: $base-spacing-unit;
    &.band-bottom-five-times {
        padding-bottom: ($base-spacing-unit * 5);
    }

    &.band-bottom-triple {
        padding-bottom: ($base-spacing-unit * 3);

        @include media-query(palm) {
            padding-bottom: $base-spacing-unit;
        }
    }
}

@include media-query(desk) {
    .band-desk {
        padding: $base-spacing-unit $base-spacing-unit 1px;
    }

    .band-lrg {
        padding-top: treble($base-spacing-unit);
        padding-bottom: double($base-spacing-unit);
    }

    .band-bleed {
        margin-left: -32px;
        margin-right: -32px;
        padding-left: $base-spacing-unit + 32px;
        padding-right: $base-spacing-unit + 32px;
    }
}

.band-secondary {
    background-color: $colour-secondary;
    color: rgba($colour-ui-light, 0.64);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $colour-ui-light;
    }
}

.band-color-ui {
    background-color: $colour-ui;
}

.band-color-ui-grey {
    background-color: $colour-ui-grey;
}

.band-ui {
    background-color: $colour-ui;
}

.band_newsletter {
    margin-top: 96px;
    margin-bottom: 96px;
    padding-top: 86px;
    padding-bottom: 96px;
    background: $base-text-colour;

    .center_content {
        margin: 0 auto;
        width: 100%;
        max-width: 460px;
        text-align: center;
    }
    .heading_newsletter {
        line-height: 26px;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
    }
    p {
        margin-bottom: 0;
        padding-top: 18px;
    }
}

.hpb_infographic {
    padding-top: 0;
    padding-bottom: 24px;

    @include media-query(desk) {
        padding-bottom: double($base-spacing-unit);
    }

    img {
        display: block;
        width: 100%;
        height: auto;
        border: 1px solid rgba(#000, 0.12);
        box-sizing: border-box;
    }
}

.band-right-none {
    padding-right: 0 !important;
}

.band-left-none {
    padding-left: 0 !important;
}

@include media-query(narrow) {
    .band-right-md {
        padding-right: $base-spacing-unit !important;
    }
}
