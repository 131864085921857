/*------------------------------------*\
    $CLEARFIX
\*------------------------------------*/

/**
 * Micro clearfix
 */

.clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}
