/*------------------------------------*\
    $FONT-FACE
\*------------------------------------*/

/**
 * Embed custom fonts
 */

@font-face {
    font-family: 'Encode Sans';
    src: url('../webfonts/encodesans-bold-webfont.woff2') format('woff2'),
         url('../webfonts/encodesans-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Encode Sans';
    src: url('../webfonts/encodesans-medium-webfont.woff2') format('woff2'),
         url('../webfonts/encodesans-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Encode Sans';
    src: url('../webfonts/encodesans-regular-webfont.woff2') format('woff2'),
         url('../webfonts/encodesans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
