/*------------------------------------*\
    $SPLIT
\*------------------------------------*/

.split {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.split-title {
    flex: 1 1 0%;
    margin-right: $base-spacing-unit;
}
