.text-columns {
    &__column-heading {
        margin-bottom: 0;
    }

    &__title {
        font-size: 24px;
        margin-bottom: $base-spacing-unit * 2;

        @include media-query(narrow) {
            font-size: $base-font-size * 1.5;
        }

        @include media-query(portable) {
            font-size: 18px;
        }
    }

    &__container {
        ul {
            li {
                padding-left: 20px;
                text-indent: -20px;

                &:before {
                    content: "";
                    margin-right: 12px;
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    border: 2px solid #ef7e25;
                    border-radius: 100px;
                }
            }
        }
    }
}
