/*------------------------------------*\
    $ACCORDION
\*------------------------------------*/

.accordion {
    @include media-query(portable) {
        border-bottom: 1px solid $colour-ui;
        margin-bottom: $base-spacing-unit;
        margin-right: -$base-spacing-unit;
        text-align: left;
    }
}

.accordion-header,
.accordion-panel {
    @include media-query(portable) {
        margin-left: -$base-spacing-unit;
    }
}

.accordion-header {
    color: $colour-primary;

    @include media-query(portable) {
        position: relative;
        padding: 17px $base-spacing-unit;
        border-top: 1px solid $colour-ui;
        margin-bottom: 0;
        cursor: pointer;
        transition: color $base-anim-speed linear;

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: $base-spacing-unit;
            width: 8px;
            height: 8px;
            border-right: 2px solid $colour-secondary;
            border-bottom: 2px solid $colour-secondary;
            transform: translateY(-50%) rotate(45deg);
            transition: transform $base-anim-speed ease-in-out, border-color $base-anim-speed linear;
        }

        &.is-active {
            &::after {
                transform: translateY(-50%) rotate(225deg);
            }
        }
    }
}

.accordion-panel {
    @include media-query(portable) {
        padding-left: $base-spacing-unit;
        padding-right: $base-spacing-unit;
        display: none;
    }

    @include media-query(desk) {
        display: block !important;
    }
}
