/*------------------------------------*\
    $FIELD
\*------------------------------------*/

// Define field gutter sizes
$field-gutter-width: 4px !default;


/**
 * Use a containing element for inputs so we can add pseudo-elements
 */

.field {
    position: relative;

    &:not(:last-child) {
        margin-bottom: $field-gutter-width;
    }
}

.field-label {
    position: absolute;
    top: halve($base-spacing-unit);
    left: 20px;
}

.field-label,
.field-label-static,
.field-control {
    line-height: ($base-line-height / $base-font-size);
}

.field-label-static {
    font-weight: 500;
    margin-top: 7px;
    margin-bottom: 4px;
    display: inline-block;

    & ~ .field-icon {
        top: 29px;
    }
}

/**
 * Use a class instead of a huge input[type=*] selector
 */

.field-control {
    width: 100%;
    background-color: $colour-ui;
    color: $colour-secondary;
    border-radius: 6px;
    border: none;
    padding: halve($base-spacing-unit) 20px;
    outline: none;
}


/**
 * Select boxes
 */

.field-select {
    position: relative;

    &::after {
        content: "";
        border: 2px solid transparent;
        border-right-color: $colour-secondary;
        border-bottom-color: $colour-secondary;
        width: 10px;
        height: 10px;
        position: absolute;
        right: 21px;
        top: 50%;
        transform: translateY(-75%) rotate(45deg);
    }

    select {
        appearance: none;
        padding-right: 48px;
    }
}


/**
 * Feedback icons
 */

.field-icon {
    position: absolute;
    color: $colour-ui-light;
    top: -6px;
    right: -6px;
    z-index: 1;
    opacity: 0;
    transition: opacity $base-anim-speed linear;
    width: $base-spacing-unit;
    height: $base-spacing-unit;
    border-radius: 99px;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

    .field-icon-valid {
        background-color: $colour-valid;

        .is-valid & {
            opacity: 1;
        }
    }

    .field-icon-invalid {
        background-color: $colour-invalid;

        .is-invalid & {
            opacity: 1;
        }
    }

/* --- Checkbox --- */
.group_checkbox {
	padding:12px 0;

	p { margin:0; padding:0; }

	.input_checkbox {
		display:table;
		position:relative;
		float:left;
		margin-top:6px;
		width:50%;

		input[type=checkbox] {
			position:absolute;
			top:0; left:0;
			z-index:1;
			width:20px; height:20px;
			opacity:0;
			cursor:pointer;
		}
		span {
			display:block;
			margin-right:12px;
			width:20px; height:20px;
			line-height:20px;
			text-align:center;
			background:#FFF;
			border:2px solid #E9EAEA;
			border-radius:4px;
			box-sizing:border-box;

			&:before {
				content:"";
				display:none;
				margin:3px;
				width:10px; height:10px;
				background:#485156;
				border-radius:2px;
			}
		}
		label {
			display:table-cell;
			vertical-align:middle;
			margin:0; padding:0;
			width:100%;
			text-align:left;
		}

		input[type=checkbox]:checked + span:before { display:block; }

		input[type=checkbox]:focus + span {
			background:none;
			border:2px solid darken(#E9EAEA, 12%);
		}
	}
	.input_checkbox.input_checkbox-focus label { font-weight:700; }
}
