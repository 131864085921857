/*------------------------------------*\
    $HEADER
\*------------------------------------*/

.header-threshold {
    position: relative;

    @include media-query(desk) {
        &.has-sticky-header {
            padding-top: 220px;
        }
    }
}

.header {
    @include media-query(desk) {
        background-color: $base-background-colour;
        transition: transform $base-anim-speed ease-in-out;
        position: relative;
        z-index: 2;
        margin-left: -32px;
        padding-left: 32px;

        &.is-sticky {
            position: fixed;
            margin-left: 0;
            padding-right: 32px;
            left: 0;
            right: 0;
            bottom: 100%;

            &.is-active {
                transform: translateY(100%);
            }
        }
    }
}

.header-body {
    padding: double($base-spacing-unit) 0px;
    position: relative;

    @include media-query(portable) {
        text-align: center;
    }

    @include media-query(desk) {
        padding: 63px 32px 33px;
    }

    .badge_10-years {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        margin-top: -80px;
        margin-left: -80px;
        width: 160px;
        height: 160px;

        @include media-query(desk-2) {
            left: auto;
            right: 272px;
            margin-left: 0;
        }
        @include media-query(portable) {
            display: none;
        }
    }
}

.header-ui {
    display: flex;
    justify-content: center;
    position: absolute;
    text-align: center;
    margin-bottom: 0;
    bottom: -20px;
    left: 0;
    right: 0;
    z-index: 1;
    transition: transform $base-anim-speed ease-in-out;

    .has-sticky-header & {
        position: fixed;
        bottom: calc(100% - 24px);
        transform: translateY(52px);
        z-index: 2;
    }

    @include media-query(desk) {
        position: absolute;
        left: auto;
        top: 54px;
        right: 0;
        max-height: 64px;
        z-index: 6;

        .has-sticky-header & {
            right: 64px;
            top: (54px - 220px);
            transform: translateY(220px);
        }
    }
}

.header-ui-item {
    &:not(:first-child) {
        // margin-left: $base-spacing-unit;

        @include media-query(desk) {
            margin-left: 16px;
        }
    }

    @include media-query(portable) {
        button {
            border-color: #fff;
            background-color: $colour-primary;
            svg {
                fill: #fff;

                // background-color: $colour-primary;
            }
        }
    }
}

.header-ui-link {
    @include media-query(portable) {
        &.band-m-right {
            margin-right: auto !important;
        }
    }

    @include btn-bare;
    display: block;
    border-radius: 100px;
    width: 24px;
    height: 24px;
    background-color: transparent;
    color: $colour-ui-grey;
    border: 2px solid $colour-ui-grey;
    position: relative;
    transition: background-color $base-anim-speed linear,
        box-shadow $base-anim-speed linear, opacity $base-anim-speed linear;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover,
    &:focus,
    &:active {
        color: $colour-ui-light;
        background-color: #e86400;
    }

    @include media-query(portable) {
        box-shadow: 0 0 12px rgba(#000, 0.16);
        border-color: $colour-primary;
        color: $colour-primary;
        $size: 40px;
        height: $size;
        width: $size;
    }

    @include media-query(desk) {
        width: 64px;
        height: 64px;

        .has-sticky-header & {
            box-shadow: 0 3px 0 rgba($colour-ui-dark, 0.12);
        }

        .is-sticky.is-active ~ .header-ui & {
            box-shadow: none;
        }
    }
}

.header-ui-tel {
    background-color: transparent;
    border: 2px solid $colour-ui-grey;

    svg {
        fill: $colour-ui-grey;
        color: $colour-ui-grey;
    }

    &:hover,
    &:focus,
    &:active {
        color: $colour-ui-light !important;
        background-color: #e86400;
    }
}

.header-ui-email {
    background-color: $colour-quaternary;

    &:hover,
    &:focus,
    &:active {
        background-color: #7cba00;
    }
}

.header-ui-toggle {
    pointer-events: none;

    .icon {
        transition: transform $base-anim-speed ease-in-out;
        transform: translate(-50%, -50%);
    }

    .has-sticky-header & {
        opacity: 1;
        pointer-events: auto;

        .icon {
            transform: translate(-50%, -50%);
        }
    }

    &.is-active .icon {
        transform: translate(-50%, -50%);
    }
}
