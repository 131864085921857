/*------------------------------------*\
    $MAP
\*------------------------------------*/

.map {
    position: relative;
    padding-top: $base-spacing-unit;
    padding-bottom: 56.25%;
    margin-bottom: $base-spacing-unit
}

    .map-element {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
