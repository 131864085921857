/*------------------------------------*\
    $PINCH
\*------------------------------------*/

/**
 * Reduce vertical margin
 */

.pinch {
    margin-bottom: halve($base-spacing-unit);
}
