/*------------------------------------*\
    $SLIDER
\*------------------------------------*/

.slider {
    border: 1px solid $colour-ui;
    margin-bottom: $base-spacing-unit;

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .slick-dots {
        bottom: $base-spacing-unit;
        right: $base-spacing-unit;
        margin-bottom: 0;
        line-height: 1;

        @include media-query(desk) {
            bottom: 32px;
            right: 32px;
        }

        button {
            border-color: rgba($colour-secondary, .48);
        }

        .slick-active button {
            background-color: $colour-secondary;
        }
    }
}

.slider-item {
    padding: $base-spacing-unit;
}
